// ============================================================================
// Navigation
// ============================================================================
.navigation {
	float: right;
	padding-top: +px-to-percent(64);

	&__link {
		display: inline-block;
		margin: 0 20px;
		a {
			display: block;
			padding: 8px 0;
			font-size: 20px;
			text-decoration: none;
			font-family: "HaptikBold";
			color: $color-white;
			font-weight: normal;
			font-style: normal;
			@include transition(box-shadow 200ms ease-in-out);
			&:hover {
				@include box-shadow(0px 7px 0px 0px $color-pageload); } }
		&--active {
			a {
				@include box-shadow(0px 7px 0px 0px $color-pageload); } } }

	&__btn {
		display: none; }

	&__eshop {
		display: inline-block;
		margin-right: 4px;
		a {
			display: block;
			padding: 5px 15px;
			@include box-shadow(0px 0px 0px 4px $color-pageload);
			color: $color-white;
			&:hover {
				@include box-shadow(0px 0px 0px 4px darken($color-pageload, 8%)); } } } }

// mobile version
.navigation {
	@media screen and (max-width: 1150px) {
		visibility: hidden;
		@include opacity(0);
		@include all-fix;
		padding: 115px 0 0;
		z-index: 1000;
		background: white;
		@include transition(visibility 0s linear 0.3s, opacity 0.3s ease);

		&--open {
			visibility: visible;
			@include opacity(1);
			@include transition-delay(0s); }



		&__inner {
			height: 100%;
			overflow: auto; }

		&__link {
			padding: 10px 0;
			display: block;

			a {
				color: $color-pageload;
				&:hover {
					box-shadow: none; } } }

		&__btn {
			padding: 3px 0;
			display: block;
			position: absolute;
			top: 20px;
			right: 15px;
			width: 50px;
			border: 1px solid white;
			@include border-radius(3px);
			z-index: 1100;
			@include transition(border-color 0.3s ease);
			span {
				height: 5px;
				display: block;
				margin: 4px 10px;
				background: white;
				@include border-radius(1px);
				@include transition(background 0.3s ease); }


			&--gallery,
			.state-nav-open & {
				border: 1px solid $color-pageload;
				span {
					background: $color-pageload; } } }

		&__eshop {
			margin: 10px 20px 0;
			a {
				color: $color-pageload; } } } }


