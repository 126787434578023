// ============================================================================
// Helpers
// ============================================================================
.ta-right {
	text-align: right; }
.ta-left {
	text-align: left; }
.ta-center {
	text-align: center; }

.flex-center {
	display: flex;
	justify-content: center; }

.on {
	&Desktop {
		display: block;
		@media screen and (max-width: 470px) {
			display: none; } }
	&Mobile {
		display: none;
		@media screen and (max-width: 470px) {
			display: block; } } }
