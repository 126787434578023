// ============================================================================
// Pages
// ============================================================================

// Default settings
body {
	overflow-x: hidden;
	overflow-y: scroll;
	line-height: 1.2;
	max-width: 100%;
	font-size: $default-font-size;
	font-weight: normal;
	font-style: normal;
	font-family: 'HaptikRegular', Arial, Helvetica, sans-serif;
	color: $color-white;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.page {
	position: relative;
	@include transition(opacity 1000ms linear 1000ms);
	&__inner {
		@include clearfix;
		width: $page-max-width+px;
		margin: 0 auto;
		@media screen and (max-width: 1150px) {
			width: 96%; } }
	&__indent {
		@include clearfix;
		padding-top: +px-to-percent(250);
		padding-bottom: 18%;
		@media screen and (max-width: 900px) {
			padding-top: 5%; } }


	// Scroll dart
	&__scroll-dart {
		z-index: 1100;
		position: absolute;
		bottom: 3%;
		width: 100%;
		&-link {
			display: block;
			margin: 0 auto;
			width: 60px;
			height: 60px;
			border: 1px solid $color-white;
			@include border-radius(60px);
			@include transition(all 300ms linear);
			&:hover {
				border-color: #1d1d1b; }
			@media screen and (max-width: 400px) {
				width: 40px;
				height: 40px; }
			img {
				display: block; } } }
	&__in {
		opacity: 1; } }




// Pages z-index and heigh settings
.page__home {
	z-index: 200;
	min-height: 400px; }
.page__aboutus {
	z-index: 200;
	min-height: 400px; }
.page__team {
	@include clearfix;
	z-index: 190;
	min-height: 400px;
	@media screen and (max-width: 800px) {
		background: $color-gray !important; } }
.page__gallery {
	z-index: 210;
	min-height: 600px; }
.page__pricelist {
	z-index: 220;
	min-height: 850px;
	@media screen and (max-width: 700px) {
		.page__indent {
			padding-bottom: 120px; } } }
.page__products {
	z-index: 220;
	min-height: 900px; }
.page__career {
	z-index: 220;
	min-height: 600px; }
.page__contact {
	z-index: 200;
	min-height: 800px; }


// About Us
.page__aboutus, .page__career {
	h2, p {
		width: 63%;
		margin: 0 auto 1em;
		@media screen and (max-width: 900px) {
			width: 64%; } }
	h2 {
		@media screen and (max-width: 500px) {
			font-size: 20px; } }
	p {
		@media screen and (max-width: 500px) {
			font-size: 14px; } }

	.page__scroll-dart {
		@media screen and (max-height: 900px) {
			bottom: 3%; } } }

.page__products {
	p {
		width: 63%;
		margin: 0 auto 1em;
		@media screen and (max-width: 900px) {
			width: 64%; }
		@media screen and (max-width: 500px) {
			font-size: 14px; } }

	.page__indent {
		div:first-of-type {
			img {
				width: 206px;
				@media screen and (max-width: 500px) {
					max-width: 153px; } } }
		div:last-of-type {
			img {
				width: 267px;
				@media screen and (max-width: 500px) {
					max-width: 184px; } } }
		img {
			@media screen and (max-width: 500px) {
				max-width: 35%; } } }
	.page__scroll-dart {
		@media screen and (max-height: 500px) {
			bottom: 0%; } }

	.flex-center {
		margin-bottom: 50px;
		@media screen and (max-width: 900px) {
			margin-bottom: 30px; } }
	.flex-center:first-child {
		@media screen and (max-width: 900px) {
			margin-top: 40px; }
		@media screen and (max-width: 500px) {
			margin-top: 40px; } }
	p:not(:last-child) {
		margin-bottom: 100px;
		@media screen and (max-width: 900px) {
			margin-bottom: 60px; } } }


.pricelist__top {
	width: 70%;
	margin: 0 auto;
	max-width: 720px;
	h3 {
		margin-bottom: 0.5em;
		margin-top: 1.5em;
		@media screen and (max-width: 600px) {
			font-size: 20px; } }
	p {
		font-size: 16px;
		@media screen and (max-width: 600px) {
			font-size: 14px; } }
	@media screen and (max-width: 900px) {
		width: 70%; }
	@media screen and (max-width: 600px) {
		width: 90%; } }

.pricelist__table {
	@include pie-clearfix;
	width: 70%;
	margin: 0 auto;
	max-width: 570px;
	font-size: 16px;
	@media screen and (max-width: 900px) {
		width: 70%; }
	@media screen and (max-width: 600px) {
		font-size: 14px;
		padding-left: 0;
		width: 90%; }
	h2 {
		margin-bottom: 0; }
	&-row {
		@include pie-clearfix;
		margin-bottom: 5px;
		&--mb0 {
			margin-bottom: 0; }
		&--divided {
			.pricelist__table-col1-inner {}
			.pricelist__table-col2-inner {}
			.pricelist__table-col3-inner {
				border-bottom: 2px solid $color-white; } }
		&--empty {
			.pricelist__table-col1-inner {}
			.pricelist__table-col2-inner {}
			.pricelist__table-col3-inner {
				font-size: 15px;
				padding: 0; } }
		small {
			font-size: 12px; } }

	&-col1 {
		float: left;
		width: 50%;
		@media screen and (max-width: 700px) {
			font-family: 'HaptikBold', arial, sans-serif; }
		&-inner {
			padding: 3px 6px; } }
	&-col2 {
		float: left;
		width: 50%;
		text-align: right; }
	//&-col3,
	//&-col4
	//	float: left
	//	width: 23%
	//	text-align: right
	//	white-space: nowrap
	//	@media screen and (max-width: 700px)
	//		width: 33.333%
	//		font-size: 14px
	//	@media screen and (max-width: 320px)
	//		font-size: 12px
	//	&-inner
	//		padding: 3px 6px


	&-note {
		padding-top: +px-to-percent(30);
		padding-left: 6px;
		font-size: 14px; }
	&-info {
		font-size: 12px;
		padding-left: 0;
		font-weight: bold;
		br {
			display: none;
			@media screen and (max-width: 1000px) {
				display: block; } } } }

.pricelist__notes {
	p {
		font-size: 16px;
		font-weight: {}
		margin-bottom: 1em; } }

// Galery
.page__gallery {
	&-text {
		width: 40%;
		@media screen and (max-height: 620px) {
			display: none; }
		@media screen and (max-width: 800px) {
			padding-left: 15%; } } }



// Contact
.page__contact {
	background: #c3c3c3;
	.page__indent {
		@media screen and (max-width: 900px) {
			padding-top: 10%; } } }

.page__contact-data {
	position: relative;
	@include pie-clearfix;
	width: 45%;
	float: left;
	text-align: right;
	@media screen and (max-width: 600px) {
		width: 100%;
		text-align: center; }
	.opening-hour {
		margin: 20px 0 0 0;
		list-style: none;
		padding: 0;
		li {
			position: relative;
			span {
				width: 100px;
				position: absolute;
				right: 180px;
				top: 0;
				display: inline-block; } }
		@media screen and (max-width: 600px) {
			li {
				font-size: 20px;
				br {
					display: none; }
				span {
					margin-right: 10px;
					position: static;
					width: auto; } } } }
	&-text {
		margin-top: -9px;
		font-size: 30px;
		font-family: 'HaptikMedium', Arial, Helvetica, sans-serif;



		@media screen and (max-width: 900px) {
			font-size: 25px; }
		a {
			color: $color-white;
			@include transition(box-shadow 200ms ease-in-out);
			&:hover {
				@include box-shadow(0px 7px 0px 0px $color-blue); } } }
	a[href^="tel:"] {
		font-size: 50px;
		@media screen and (max-width: 1110px) {
			font-size: 40px; }
		@media screen and (max-width: 900px) {
			font-size: 30px; } } }

.page__career {
	a {
		color: $color-white;
		text-decoration: underline;

		&:hover {
			text-decoration: none; } } }

.page__contact-social {
	position: absolute;
	z-index: 9999;
	bottom: -142px;
	right: -10px;
	@media screen and (max-width: 900px) {
		bottom: -80px; }
	@media screen and (max-width: 600px) {
		bottom: 0;
		left: 0; }
	a {
		display: inline-block;
		width: 60px;
		height: 60px;
		margin: 0 10px;
		@include border-radius(60px);
		background: #fff;
		z-index: 9999;
		&:hover {
			background: $color-pageload; }
		@media screen and (max-width: 600px) {
			width: 40px;
			height: 40px; } }
	img {
		display: block;
		width: 50px;
		margin: 5px auto;
		@media screen and (max-width: 600px) {
			width: 37px;
			margin: 2px auto; } }

	&--homepage {
		padding-right: 15%;
		bottom: 3%;
		height: 60px;
		@media screen and (max-width: 800px) {
			padding-right: 5%; }
		@media screen and (max-width: 637px) {
			left: 0;
			right: 0;
			bottom: 15%;
			padding: 0;
			text-align: center; }
		@media screen and (max-width: 400px) {
			bottom: 8%; } } }

.page__contact-map {
	@include clearfix;
	width: 50%;
	float: right;
	min-height: 400px;
	@media screen and (max-width: 600px) {
		width: 100%; } }








