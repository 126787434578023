// ============================================================================
// Pageload
// ============================================================================
.pageload {
	-webkit-transform: translate3d(0, 0, 0);
	z-index: 9999;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	&.animate {
		.pageload__bottom {
			top: 1000px;
			left: -1500px;
			@include animation(cutingPage 2500ms ease); }
		.pageload__top {
			top: -1000px; } }
	&__top {
		position: relative;
		top: 0;
		height: 50%;
		width: 100%;
		padding: 0 35% 0 0;
		background: $color-pageload url("../../images/logo_bile_vrsek.png") no-repeat 35% 100%;
		background-size: 15%;
		@include transition(top 300ms ease-in 500ms);
		@media screen and (max-width: 800px) {
			background-size: 25%; }
		@media screen and (max-width: 400px) {
			background-size: 35%; } }
	&__bottom {
		float: left;
		z-index: 1;
		position: relative;
		left: 0;
		top: 0;
		height: 150%;
		width: 100%;
		padding: 0 35% 0 0;
		background: $color-pageload url("../../images/logo_bile_spodek.png") no-repeat 35% 0%;
		background-size: 15%;
		@include transition(top 900ms linear, left 2000ms linear);
		@media screen and (max-width: 800px) {
			background-size: 25%; }
		@media screen and (max-width: 400px) {
			background-size: 35%; } } }
