// ============================================================================
// Header
// ============================================================================
.header {
	opacity: 0;
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	@include transition(opacity 1000ms linear 500ms);
	&__inner {
		@include clearfix;
		width: $page-max-width+px;
		margin: 0 auto;
		@media screen and (max-width: 1150px) {
			width: 96%; } }

	// logo
	&__logo {
		float: left;
		width: +px-to-percent(150);
		margin: 0;
		padding-top: +px-to-percent(30);
		z-index: 1100;
		@media screen and (max-width: 900px) {
			position: fixed;
			top: 0;
			left: 2%; }

		@media screen and (max-width: 600px) {
			width: 60px;
			padding-top: 20px;
			padding-left: 10px;
			max-width: 60px; }

		a {
			display: block;
			position: relative;
			img {
				position: absolute;
				top: 0;
				left: 0;
				@include transition(opacity 350ms linear); } }

		&--holder {
			position: static !important;
			top: auto !important;
			left: auto !important;
			@media screen and (max-width: 600px) {
				width: 113%; } } }


	&__in {
		opacity: 1; }
	&__out {
		opacity: 0; } }



// Logo color control
.header__logo--white {
	opacity: 1; }

.header__logo--blue {
	opacity: 0; }

// mobile menu
.state-nav-open .header__logo {
	a .header__logo--blue {
		opacity: 1; }
	a .header__logo--white {
		opacity: 0; } }





@media screen and (max-width: 600px) {
	.header {
		height: 80px;
		display: block;
		background: rgba(0, 0, 0, .75);
 } }		//&--pricelist

