// ============================================================================
// Mixins
// ============================================================================

// css feeder
@mixin all-abs {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }

@mixin all-fix {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }

// pocita % z hodnoty druhym parametrem nastavi parenta ze ktereho bude pocitat...
@function px-to-percent($size, $default: $page-max-width) {
	@return ($size / $default) * 100%; }
