// ============================================================================
// Fonts
// ============================================================================
@font-face {
	font-family: 'HaptikBold';
	src: url("../../fonts/gt-haptik-bold-webfont.eot");
	src: url('../../fonts/gt-haptik-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gt-haptik-bold-webfont.woff2') format('woff2'), url('../../fonts/gt-haptik-bold-webfont.woff') format('woff'), url('../../fonts/gt-haptik-bold-webfont.ttf') format('truetype'), url('../../fonts/gt-haptik-bold-webfont.svg#gt_haptiklight') format('svg');
	font-weight: normal;
	font-style: normal; }


@font-face {
	font-family: 'HaptikLight';
	src: url('../../fonts/gt-haptik-light-webfont.eot');
	src: url('../../fonts/gt-haptik-light-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gt-haptik-light-webfont.woff2') format('woff2'), url('../../fonts/gt-haptik-light-webfont.woff') format('woff'), url('../../fonts/gt-haptik-light-webfont.ttf') format('truetype'), url('../../fonts/gt-haptik-light-webfont.svg#gt_haptiklight') format('svg');
	font-weight: normal;
	font-style: normal; }


@font-face {
	font-family: 'HaptikMedium';
	src: url('../../fonts/gt-haptik-medium-webfont.eot');
	src: url('../../fonts/gt-haptik-medium-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gt-haptik-medium-webfont.woff2') format('woff2'), url('../../fonts/gt-haptik-medium-webfont.woff') format('woff'), url('../../fonts/gt-haptik-medium-webfont.ttf') format('truetype'), url('../../fonts/gt-haptik-medium-webfont.svg#gt_haptiklight') format('svg');
	font-weight: normal;
	font-style: normal; }


@font-face {
	font-family: 'HaptikRegular';
	src: url('../../fonts/gt-haptik-regular-webfont.eot');
	src: url('../../fonts/gt-haptik-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/gt-haptik-regular-webfont.woff2') format('woff2'), url('../../fonts/gt-haptik-regular-webfont.woff') format('woff'), url('../../fonts/gt-haptik-regular-webfont.ttf') format('truetype'), url('../../fonts/gt-haptik-regular-webfont.svg#gt_haptiklight') format('svg');
	font-weight: normal;
	font-style: normal; }

