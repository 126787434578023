// ============================================================================
// Team
// ============================================================================
.no_paralax {
	.team-mates {
		position: absolute; }

	#team {
		background: url("../images/team/interier.jpg") no-repeat;
		background-size: cover !important;
		transition: background-image 100ms linear; } }
.team-mates {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;

	@supports (-ms-ime-align: auto) {
		position: static !important;
		height: 100vh; }

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		position: static !important;
		height: 100vh; }

	@media screen and (max-width: 800px) {
		display: none; }

	&__overlay {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.6); }

	&__column {
		max-width: 330px;
		height: 100%;
		padding: 200px 10px 0 10px;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin-top: 200px;
			width: 100%; }

		&:nth-of-type(even) {
			.team-mates__mate:nth-of-type(1) {
				margin-top: 150px;

				@media screen and (max-height: 900px) {
					margin-top: 0; } } } }

	&__mate {
		color: $color-white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 60px;

		img {
			@include border-radius(50%);
			width: 160px;
			height: 160px;
			margin-bottom: 20px;

			@media screen and (max-width: 1000px) {
				width: 140px;
				height: 140px; }


			&:hover {
				cursor: pointer;
				box-shadow: 0 0 0 3px $color-white; } }

		p {
			font-size: 22px;

			@media screen and (max-width: 1000px) {
				font-size: 18px; } } } }

.mobile-team-mates {
	display: none;
	@media screen and (max-width: 800px) {
		display: block; }
	h3 {
		font-size: 18px; }
	img {
		width: 50%;
		max-width: 200px;
		height: 50%;
		display: block;
		margin: 0 auto 2em;
		@include border-radius(50%);

		&:last-of-type {
			margin-bottom: 100px; } } }

.parallax-mirror {
	background: $color-black; }

.team-container {
	@supports (-ms-ime-align: auto) {
		height: 100vh; }

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 100vh; } }
