// ============================================================================
// Variables
// ============================================================================
// Page dimensions
$page-max-width:		1150;
$default-font-size:		22px;


// Colors
$color-black:			#000;
$color-white:			#fff;

$color-blue:			#00afe9;
$color-pageload:		#D4C7BE;
$color-gray:		    #c3c3c3;
