// ============================================================================
// Typography
// ============================================================================
strong, b {
	font-family: "HaptikBold", Arial, Helvetica, sans-serif; }

a {
	text-decoration: none; }

h1, h2, h3, h4, h5 {
	font-family: "HaptikMedium", Arial, Helvetica, sans-serif; }

h1, .h1 {
	font-size: 42px;
	margin-bottom: 1em; }
h2, .h2 {
	font-size: 36px;
	margin-bottom: 1em; }
h3, .h3 {
	font-size: 27px;
	margin-bottom: 1em; }
h4, .h4 {
	font-size: 24px;
	margin-bottom: 1em; }
h5, .h5 {
	font-size: 22px;
	margin-bottom: 1em; }

p {
	line-height: 1.5;
	margin-bottom: 1em;
	font-family: "HaptikLight", Arial, Helvetica, sans-serif;
	font-size: 20px; }
